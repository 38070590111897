<template>
	<tr>
		<td><input v-model="data.checked" :value="data.checked" class="checkbox-height"
				   type="checkbox"/></td>
		<td class="text-xs">{{ data.customer_name }}</td>
		<td class="text-xs">
			<span>
			{{ data.customer_number }}
		</span></td>
		<td>
            <span>
                <span v-on:click="openBuyer"
					  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-blue-900 cursor-pointer">
                    <img src="./../../../assets/images/icons/edit.png" alt="">
                </span>
            </span>
		</td>
	</tr>
</template>

<script>
import {saveBuyer, saveCompany, saveCompanyInfo,clearCartItem} from "../../../services/storage-window";

export default {
	name: "BuyerTbl",
	props: {
		data: {
			type: Object,
			required: true
		}
	},
	methods: {
		openBuyer: function () {
			if (this.data.user) {
				const loading = this.$loading({
					lock: true,
					text: this.$t('settingUpAccount'),
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				})
				let payload = {
					'buyer_id': this.data.user.id,
					'referrer': 'administrator'
				}

				saveCompany('')
				saveBuyer(payload)
				let url = `?is_connected=True`
				this.$store.dispatch('customer/getConnectedShopList', {data: url}).then(resp => {
					if (resp.data.suppliers.length > 0) {
						saveCompanyInfo(resp.data.suppliers[0])
					}
					let routeData = this.$router.resolve({name: 'customerdash'});
					clearCartItem()
					setTimeout(function () {
						loading.close()
						window.open(routeData.href, '_blank')
					}, 500)
				})

			} else {
				this.$services.helpers.notification(this.$t('notConnectedToCompany'), 'error', this)
			}
		}
	}
}
</script>

<style scoped>

</style>
