<template>
	<div data-cy='table1' class="w-full mt-3"> <!--pl-5 pr-5-->
		<div class="w-full flex justify-center md:justify-end mb-4 bg-white border rounded-md p-2">
			<div class="flex flex-col md:flex-row justify-between align-items-center space-x-4">
						<span class="">
                            <input type="text" v-model="search" @input="searchFilter"
								   class="flex justify-between align-items-center space-x-4 focus:outline-none bg-gray-200 border px-3 py-2 rounded-md text-gray-500"
								   v-bind:placeholder="$t('search')">
                        </span>
			</div>
		</div>
		<div class="table-responsive rounded-lg bg-white border">
			<table v-loading='loading' style="width: 100%;" id="exampledestroy"
				   class="table table-hover table-bordered">
				<thead>
				<tr>
					<th width='10px'><input type="checkbox" name="checkboxAll"
											style="font-size: 10px !important"
											class="font-xs checkbox-height v-article-5"/>
					</th>
					<th class="text-xs" width='60px'>
						<div class="d-flex justify-content-between align-items-center">
							{{ $t('name') }}<span class="flex flex-col"><i

							class="lnr-chevron-up cursor-pointer small"></i><i

							class="lnr-chevron-down cursor-pointer small"></i></span></div>
					</th>
					<th class="text-xs v-article-6" width='100px '>{{ $t('organizationNumber') }}</th>
					<th class="text-xs v-article-3" width='100px'>
						<div
							class="d-flex justify-content-between align-items-center whitespace-nowrap mr-2">
							{{ $t('logo') }}
						</div>
					</th>
					<th class="text-xs w-20 v-article-2">{{ $t('actions') }}</th>
				</tr>
				</thead>
				<tbody v-for="(company, i) in GET_COMPANIES.results" :key="i">
				<company-tbl :data="company"/>
				</tbody>
			</table>
		</div>
		<div class="flex flex-col md:flex-row justify-between mb-4">
                        <span class="flex justify-between align-items-center space-x-5 mt-4">
                            <span class="text-base">{{ $t('show') }}</span>
                            <span>
                                <select v-model="limit" @change="changeLimit" class="form-select">
                                    <option value="50" selected>50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="400">400</option>
                                    <option value="500">500</option>
                                </select>
                            </span>
                        </span>
			<div class="pagination-block">
				<paginate
					:click-handler="changePageNumber"
					:page-count="1"
					prev-text="<"
					next-text=">"
					:container-class="'pagination space-x-2'">
				</paginate>
			</div>
		</div>
	</div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import {mapGetters} from "vuex"
import companyTbl from "./companyTbl";
import debounce from 'lodash.debounce'

export default {
	name: "companies",
	components: {
		paginate: Paginate,
		companyTbl
	},
	data: function () {
		return {
			loading: false,
			limit: 40,
			search: ''
		}
	},
	computed: {
		...mapGetters({
			GET_COMPANIES: 'administrator/GET_COMPANIES'
		})
	},
	methods: {
		getCompanies() {
			let URL = `?limit=${this.limit}`
			this.$store.dispatch('administrator/getCompanies', {data: URL}).then(() => {
				this.loading = false
			}).catch(err => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		changeLimit: function () {
			let URL = `?limit=${this.limit}`
			if (this.search) {
				URL += `&search=${this.search}`
			}
			this.loading = true
			this.$store.dispatch('administrator/getCompanies', {data: URL}).then(() => {
				this.loading = false
			}).catch(err => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		changePageNumber: function (num) {
			let URL = `?limit=${this.limit}&page=${num}`
			if (this.search) {
				URL += `&search=${this.search}`
			}
			this.loading = true
			this.$store.dispatch('administrator/getCompanies', {data: URL}).then(() => {
				this.loading = false
			}).catch(err => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		searchFilter: debounce(function () {
			if (this.search) {
				let URL = `?limit=${this.limit}`
				if (this.search) {
					URL += `&search=${this.search}`
				}
				this.loading = true
				this.$store.dispatch('administrator/getCompanies', {data: URL}).then(() => {
					this.loading = false
				}).catch(err => {
					this.loading = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
			} else {
				this.getCompanies()
			}
		}, 500)
	},
	mounted() {
		this.getCompanies()
	}
}
</script>

<style scoped>

</style>
