<template>
	<div class="app-main__inner">
		<div class="container rounded md:p-3">
			<div class="row no-gutters">
				<div class="col-md-12 col-lg-12 h-full">
					<div data-cy='count-cards' class="row mb-3 dm-sans-font mb-5">
						<div class="col-md-6 col-lg-3 col-xl-3 mb-3">
							<div class="card flex flex-col p-1 bg-theme-blue rounded-lg r-min-h">
								<div class="flex justify-center mt-2 mb-2">
									<img src="./../../assets/images/icons/money.png" alt="">
								</div>
								<div class="flex justify-center text-center text-white text-xs"><span
									class="w-20">{{ $t('totalSubscription') }}</span></div>
								<div class="text-center mb-3 lg:bottom-0 lg:absolute lg:w-full">
									<span class="text-4xl text-white font-semibold" id="total-orders-today">{{
											dashboardMetrics.total_subscription
										}}</span>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-3 col-xl-3 mb-3">
							<div class="card flex flex-col p-1 bg-theme-yellow border-blue-400 rounded-lg r-min-h">
								<div class="flex justify-center mt-2 mb-2">
									<img src="./../../assets/images/icons/money.png" alt="">
								</div>
								<div class="flex justify-center text-center text-white text-xs"><span
									class="w-20">{{ $t('totalSupplier') }}</span></div>
								<div class="text-center mb-3 lg:bottom-0 lg:absolute lg:w-full">
									<span class="text-4xl text-white font-semibold" id="total-orders-month">{{
											$services.helpers.animateValue('total-orders-month', 0, dashboardMetrics.total_supplier || 0, 4000)
										}}</span>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-3 col-xl-3 mb-3">
							<div class="card flex flex-col p-1 bg-theme-blue rounded-lg r-min-h">
								<div class="flex justify-center mt-2 mb-2">
									<img src="./../../assets/images/icons/money.png" alt="">
								</div>
								<div class="flex justify-center text-center text-white text-xs"><span
									class="w-32">{{ $t('totalBuyer') }}</span></div>
								<div class="text-center mb-3 lg:bottom-0 lg:absolute lg:w-full">
									<span class="text-4xl text-white font-semibold" id="total-customers">{{
											$services.helpers.animateValue('total-customers', 0, dashboardMetrics.total_buyer || 0, 1000)
										}}</span>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-3 col-xl-3 mb-3">
							<div class="card flex flex-col p-1 bg-theme-green rounded-lg r-min-h">
								<div class="flex justify-center mt-2 mb-2">
									<img src="./../../assets/images/icons/money.png" alt="">
								</div>
								<div class="flex justify-center text-center text-white text-xs"><span
									class="w-32">{{ $t('totalUser') }}</span></div>
								<div class="text-center mb-3 lg:bottom-0 lg:absolute lg:w-full">
									<span class="text-4xl text-white font-semibold" id="average-order">{{
											$services.helpers.animateValue('average-order', 0, dashboardMetrics.total_user || 0, 1000)
										}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
			<div class="row flex flex-col md:flex-row justify-between align-items-center padding-header">
				<div>
					<div>
						<div class="flex justify-between align-items-center space-x-3 mb-3 cursor-pointer"
							 v-if="tab === 1">
							<span data-cy='article-heading'
								  class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
									$t('companies')
								}}</span>
						</div>
					</div>
					<div class="flex justify-between align-items-center space-x-3 mb-3" v-if="tab === 2">
						<span data-cy='price-manage'
							  class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
								$t('buyers')
							}}</span>
					</div>
				</div>
				<div class="div-action">
					<div
						class="flex justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xl">
						<span data-cy='tab1' @click="changeTab(1)"
							  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-l-md"
							  :class="tab === 1 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
								$t('companies')
							}}</span>
						<span data-cy='tab2' @click="changeTab(2)"
							  class="v-article-7 text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-r-md"
							  :class="tab === 2 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
								$t('buyers')
							}}</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<companies v-if="tab === 1"/>
					<buyers v-if="tab === 2"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import companies from "./components/companies";
import Buyers from "./components/Buyers";

export default {
	name: "Dashboard",
	components: {
		paginate: Paginate,
		companies,
		Buyers
	},
	data: function () {
		return {
			dashboardMetrics: {
				total_subscription: 0,
				total_supplier: 0,
				total_buyer: 0,
				total_user: 0,
			},
			loading: false,
			limit: 40,
			tab: 1
		}
	},
	methods: {
		getDashboardMetric: function () {
			this.$store.dispatch('administrator/getDashboardMetrics').then(response => {
				this.dashboardMetrics = response.data
			}).catch(err => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		changeTab: function (tab) {
			this.tab = parseInt(tab)
		}
	},
	mounted() {
		this.getDashboardMetric()
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200&display=swap');

.bg-custom-theme-blue {
	background: #8AACDE !important;
}

.bg-theme-pink {
	background: #FEF1F5;
	border: 0.5px solid $theme-primary-color;
}

.bg-theme-blue {
	background: #303DB7;
	border: 0.5px solid #303DB7;
}

.bg-theme-yellow {
	background: #F1D132;
	border: 0.5px solid #F1D132;
}

.bg-theme-green {
	background: #12CA5B;
	border: 0.5px solid #12CA5B;
}

.text-tertiary {
	color: $theme-tertiary-color !important;
}

.text-secondary {
	color: $theme-secondary-color !important;
}

.text-color-theme {
	color: $theme-primary-color !important;
}

.bg-custom-theme-teal {
	background: #85CED2 !important;
}

.bg-custom-theme-green {
	background: #BBE5AC !important;
}

.bg-custom-theme-gray {
	background-color: #fff !important;
}

.unset-opa-increa-size {
	opacity: unset !important;
	font-size: 1.3rem;
}

.card-text-num {
	color: #7E3469 !important;
	font-weight: 800;
	float: right;
}

.card-header {
	height: unset !important;
}

.dashboard-theme-text {
	font-family: 'Mulish', sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	// line-height: 22px;
	letter-spacing: 0.30000001192092896px;
	text-align: center;
}

.card-side-4 {
	background: $production-theme;
}

.select-pad {
	padding-bottom: 0.3rem !important;
	padding-top: 0.3rem !important;
}

.padding-header {
	padding-left: 16px;
	padding-right: 16px;
}
</style>
