<template>
	<tr>
		<td><input v-model="data.checked" :value="data.checked" class="checkbox-height"
				   type="checkbox"/></td>
		<td class="text-xs">{{ data.name }}</td>
		<td class="text-xs"><span>
			{{ $services.helpers.cleanData(data.organization_number) }}
		</span></td>
		<td>
			<div v-if="data.company_logo !== null">
				<div class="flex space-x-2">
					<v-lazy-image class="w-10 hover:w-20 cursor-pointer" :src="data.company_logo" alt=""/>
				</div>
			</div>
		</td>
		<td>
            <span v-on:click="openCompany">
                <span
					class="flex justify-center align-items-center h-7 w-7 rounded-full bg-blue-900 cursor-pointer">
                    <img src="./../../../assets/images/icons/edit.png" alt="">
                </span>
            </span>
		</td>
	</tr>
</template>

<script>
import VLazyImage from 'v-lazy-image/v2'
import {saveBuyer, saveCompany} from "../../../services/storage-window";

export default {
	name: "companyTbl",
	components: {
		VLazyImage
	},
	props: {
		data: {
			type: Object,
			required: true
		}
	},
	methods: {
		openCompany: function () {
			let self = this
			let routeData = this.$router.resolve({name: 'dashboard'});
			saveBuyer({})
			setTimeout(function () {
				saveCompany(self.data.id)
				window.open(routeData.href, '_blank')
			}, 200)
		}
	},
	mounted() {
	}
}
</script>

<style scoped>

</style>
